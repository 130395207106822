import React from 'react';
import { Element } from 'react-scroll';
import { IconWrapped } from '../Icon';
import Container from '../Container';
import SectionHead from '../../modules/SectionHead';
import heroImg from '../../assets/foundry.jpg';
import logoImg from '../../assets/logo.svg';
import './styles.css';

const ContactInfo = ({ icon, title, children }) => (
  <div className="contact__data">
    <IconWrapped name={icon} size="sm" color="white" bgColor="regent-st-blue" />
    <div>
      <h5 className="title">{title}</h5>
      {children}
    </div>
  </div>
);

const Contact = () => (
  <Container>
    <section className="contact">
      <Element name="contact" className="contact__inner">
        <div className="contact__head">
          <SectionHead title="CONTACTO" subtitle="Ponte en contacto con nosotros" />
        </div>
        <ContactInfo icon="message-square" title="Datos de contacto">
          <p>
            <a href="mailto:beneyto@fundicionesvallminor.com" rel="noopener noreferrer">
              beneyto@fundicionesvallminor.com
            </a>
          </p>
          <p>986299153</p>
          <p>626393918</p>
        </ContactInfo>
        <ContactInfo icon="map-pin" title="Dónde encontrarnos">
          <p>C/ Casás 98, Comesaña </p>
          <p>36213 (Vigo, Pontevedra)</p>
          <p>España</p>
        </ContactInfo>
        <img src={logoImg} alt="Logo" with="480" height="360" />
      </Element>
    </section>
  </Container>
);

const Footer = () => (
  <React.Fragment>
    <Contact />
    <section className="footer" style={{ backgroundImage: `url(${heroImg})` }}>
      <div className="footer__content">
        <Container>
          <div className="footer__row">
            <p>Fundiciones Vall-Miñor S.L 2022</p>
          </div>
        </Container>
      </div>
    </section>
  </React.Fragment>
);

export default Footer;
