import React from 'react';
import feather from 'feather-icons';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import Company from '../components/Company';
import Foundry from '../components/Foundry';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';

class IndexPage extends React.Component {
  componentDidMount() {
    feather.replace();
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Fundiciones Vall-Miñor S.L."
          keywords={[
            'fundicion',
            'foundry',
            'industria',
            'industry',
            'metal',
            'aluminio',
            'aluminum',
            'bronce',
            'bronze',
            'latón',
            'brass',
            'naval',
            'automoción',
          ]}
          description="Fundición de metales no férricos"
        />
        <Hero />
        <Company />
        <Foundry />
        <Gallery />
        <Footer />
      </Layout>
    );
  }
}

export default IndexPage;
