import React from 'react';
import { Element } from 'react-scroll';
import { StaticQuery, graphql } from 'gatsby';
import { chunk, sum } from 'lodash';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import Container from '../Container';
import GalleryNav from '../GalleryNav';
import GalleryImg from '../GalleryImg';
import './styles.css';

const lightboxOptions = {
  settings: { autoplaySpeed: 5000, hideControlsAfter: false },
  buttons: { showDownloadButton: false },
  caption: { captionFontWeight: 700 },
  progressBar: { fillColor: '#f26c4f', backgroundColor: '#1b325f' },
};

class Gallery extends React.Component {
  state = {
    filter: 'all',
    itemsPerRow: 3,
  };

  componentDidMount() {
    // TODO change itemsPerRow at certain breakpoints
  }

  handleChangeFilter = filter => {
    this.setState({ filter });
  };

  calcImageWidth(index, aspectRatio, rowAspectRatioSum) {
    const { itemsPerRow } = this.state;
    const width = `${(aspectRatio / rowAspectRatioSum) * 100}%`;
    const margin = (index + 1) % itemsPerRow === 0 ? 0 : 1; // margin set by gallery__img class
    return `calc(${width} - ${margin}rem)`;
  }

  render() {
    const { filter, itemsPerRow } = this.state;
    const { images } = this.props;
    const rows = chunk(images, itemsPerRow);

    return (
      <Element name="gallery">
        <Container>
          <GalleryNav active={filter} onChangeFilter={this.handleChangeFilter} />
          <SimpleReactLightbox>
            <SRLWrapper options={lightboxOptions}>
              <div className="gallery">
                {rows.map(row => {
                  const rowAspectRatioSum = sum(row.map(image => image.aspectRatio));

                  return row.map((image, index) => {
                    const width = this.calcImageWidth(index, image.aspectRatio, rowAspectRatioSum);

                    if (filter !== 'all' && filter !== image.type) {
                      return '';
                    }

                    return <GalleryImg key={image.id} image={image} width={width} />;
                  });
                })}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </Container>
      </Element>
    );
  }
}

export default props => (
  <StaticQuery
    query={allImagesQuery}
    render={data => (
      <Gallery
        images={data.allGalleryYaml.edges.map(({ node }, i) => ({
          id: node.image.id,
          caption: `${node.title} ${node.alloy ? `- ${node.alloy}` : ''}`,
          type: node.type,
          ...node.image.childImageSharp.fluid,
        }))}
        {...props}
      />
    )}
  />
);

const allImagesQuery = graphql`
  {
    allGalleryYaml {
      edges {
        node {
          title
          alloy
          type
          image {
            id
            childImageSharp {
              fluid {
                aspectRatio
                originalImg
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
