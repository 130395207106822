import React from 'react';
import './styles.css';

const SectionHead = ({ title, subtitle }) => (
  <React.Fragment>
    <h4 className="head__title">{title}</h4>
    <h3 className="head__subtitle">{subtitle}</h3>
  </React.Fragment>
);

export default SectionHead;
