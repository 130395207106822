import React from 'react';
import { Element } from 'react-scroll';
import Container from '../Container';
import Icon from '../Icon';
import SectionHead from '../../modules/SectionHead';
import './styles.css';

const Alloy = ({ name, properties }) => (
  <div className="foundry__alloy">
    <h3 className="foundry__alloy-name">{name}</h3>
    <ul className="foundry__alloy-props">
      {properties.map((p, i) => (
        <li key={i} className="foundry__alloy-prop">
          <Icon name="check-circle" size="sm" /> <span>{p}</span>
        </li>
      ))}
    </ul>
  </div>
);

const Foundry = () => (
  <Element name="foundry" className="foundry">
    <Container>
      <SectionHead title="CÓMO LO HACEMOS" subtitle="Fundimos en aleaciones no férricas" />
    </Container>
    <div className="foundry__alloys">
      <Alloy name="Aluminio" properties={['Al-Si', 'Al-Mg', 'Al-Cu', 'Al-Zn']} />
      <Alloy name="Bronce Estaño" properties={['90-10', '88-12', '86-14', '80-20']} />
      <Alloy name="Bronces Especiales" properties={['CuNiAl', 'Cuproaluminio', 'Manganeso', 'Cuproniquel']} />
      <Alloy name="Bronces Rojos" properties={['RG-5', 'RG-7', 'RG-10']} />
      <Alloy name="Latón" properties={['L. Naval', 'L 60-40']} />
    </div>
  </Element>
);

export default Foundry;
