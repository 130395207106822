import React from 'react';
import { Element } from 'react-scroll';
import { IconWrapped } from '../Icon';
import Container from '../Container';
import SectionHead from '../../modules/SectionHead';
import './styles.css';

const CompanyAttribute = ({ icon, title, desc }) => (
  <div className="company__attribute">
    <IconWrapped name={icon} color="white" bgColor="regent-st-blue" />
    <h4>{title}</h4>
    <p>{desc}</p>
  </div>
);

const Company = () => (
  <Container>
    <section className="company">
      <Element name="company">
        <SectionHead title="QUÉ HACEMOS" subtitle="En qué trabajamos" />
        <div className="company__attributes">
          <CompanyAttribute
            icon="aperture"
            title="Aleaciones"
            desc="Aleaciones de metales no férricos: Aluminio, Bronce y Latón."
          />
          <CompanyAttribute
            icon="pie-chart"
            title="Sectores"
            desc="Naval, industrial, automoción, construcción, obras públicas, artístico, cerrajería."
          />
          <CompanyAttribute 
            icon="filter"
            title="Técnica"
            desc="Fundición por gravedad en coquilla metálica."
          />
          <CompanyAttribute
            icon="settings"
            title="Piezas"
            desc="Fundimos piezas unitarias o series pequeñas y medianas."
          />
          <CompanyAttribute
            icon="box"
            title="Modelos"
            desc="Construcción de modelos en madera, resina, poliestireno y metálicos."
          />
          <CompanyAttribute
            icon="type"
            title="Placas"
            desc="Fundición de placas para inauguraciones, profesionales, corporativas, rotulaciones o figuras artísticas."
          />
        </div>
      </Element>
    </section>
  </Container>
);

export default Company;
