import React from 'react';
import heroImg from '../../assets/foundry.jpg';
import './styles.css';

const Hero = () => (
  <section className="hero" style={{ backgroundImage: `url(${heroImg})` }}>
    <div className="hero__content">
      <div className="hero__claim">
        <h1>Fundición de metales no férricos</h1>
      </div>
    </div>
  </section>
);

export default Hero;
