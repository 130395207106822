import React from 'react';
import Img from 'gatsby-image';

const GalleryImg = ({ image, width }) => (
  <div className="gallery__img" style={{ width }}>
    <Img fluid={image} title={image.caption} alt={image.caption} />
  </div>
);

export default GalleryImg;
