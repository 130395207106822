import React from 'react';
import cn from 'classnames';
import SectionHead from '../../modules/SectionHead';
import './styles.css';

const Filter = ({ name, label, active, onChangeFilter }) => (
  <button
    className={cn('gallery__filter', { 'gallery__filter--active': active === name })}
    onClick={() => onChangeFilter(name)}
  >
    {label}
  </button>
);

const GalleryNav = ({ active, onChangeFilter }) => (
  <div className="gallery__nav">
    <div>
      <SectionHead title="NUESTRO TRABAJO" subtitle="Últimos trabajos realizados" />
    </div>
    <div className="gallery__filters">
      <Filter name="all" label="Todas" active={active} onChangeFilter={onChangeFilter} />
      <Filter name="nav" label="Naval" active={active} onChangeFilter={onChangeFilter} />
      <Filter name="ind" label="Industrial" active={active} onChangeFilter={onChangeFilter} />
      <Filter name="pwo" label="Obra pública" active={active} onChangeFilter={onChangeFilter} />
      <Filter name="art" label="Artístico" active={active} onChangeFilter={onChangeFilter} />
    </div>
  </div>
);

export default GalleryNav;
